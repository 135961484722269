<template>
  <div class="login">
    <div class="loginTop clearfix">
      <h1 class="fl">
        <img src="../assets/logo.png" alt />
      </h1>
      <p class="topAbout fr">如有疑问请致电400-086-1786</p>
    </div>
    <div class="loginContent">
      <div class="welcome"></div>
      <div class="welcomeCH"></div>
      <div class="loginLogo"></div>
      <div class="eavesLeft"></div>
      <div class="eavesRight"></div>
      <div class="consulting"></div>
      <div class="focus">
        <img src="../assets/focus.png" alt />
      </div>
      <!-- 登录 -->
      <div class="loginFrom from" v-if="fromstatus == 'login'">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="密码登录" name="first">
            <el-form
              ref="loginForm"
              :model="loginForm"
              key="loginForm"
              label-width="10px"
              :rules="loginFormRules"
            >
              <el-form-item prop="username">
                <el-input
                  placeholder="请输入用户名"
                  v-model="loginForm.username"
                ></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  placeholder="请输入密码"
                  show-password
                  v-model="loginForm.password"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code" class="pCode">
                <el-input style="width: 194px" v-model="loginForm.code"></el-input>
                <img style="width: 140px" :src="loginPCode" alt @click="getOnly" />

                <p class="ForgotPassword" @click="fromStatusChange('RePassword')">
                  忘记密码
                </p>
              </el-form-item>
              <el-form-item>
                <el-button @click.prevent="login">登录</el-button>
              </el-form-item>
              <p class="goRegistered">
                <span class="goRegisteredLfet">没有账号，</span>
                <span class="goRegisteredRight" @click="fromStatusChange('registered')"
                  >马上注册</span
                >
              </p>
              <p class="copyright">鄂ICP备18021828号-2</p>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="验证码登录" name="second">
            <el-form
              ref="codeLoginFrom"
              :model="codeLoginFrom"
              label-width="10px"
              class="VerificationForm"
              :rules="codeLoginFromRules"
            >
              <el-form-item prop="mobile">
                <el-input
                  placeholder="请输入手机号"
                  v-model="codeLoginFrom.mobile"
                ></el-input>
              </el-form-item>
              <el-form-item class="VerificationInput" prop="code">
                <el-input placeholder="请输入验证码" v-model="codeLoginFrom.code">
                  <span
                    slot="suffix"
                    class="getVerificationCode"
                    @click="getVerificationCode(2)"
                    >{{ getCodeTime }}</span
                  >
                </el-input>
              </el-form-item>
              <el-form-item prop="verifycode" class="pCode">
                <el-input
                  style="width: 194px"
                  v-model="codeLoginFrom.verifycode"
                ></el-input>
                <img style="width: 140px" :src="loginPCode" alt @click="getOnly" />

                <p class="ForgotPassword" @click="fromStatusChange('RePassword')">
                  忘记密码
                </p>
              </el-form-item>
              <el-form-item>
                <el-button @click="codeLogin">登录</el-button>
              </el-form-item>
              <p class="goRegistered">
                <span class="goRegisteredLfet">没有账号，</span>
                <span class="goRegisteredRight" @click="fromStatusChange('registered')"
                  >马上注册</span
                >
              </p>
              <p class="copyright">鄂ICP备18021828号-2</p>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 注册 -->
      <div class="registeredFrom from" v-else-if="fromstatus == 'registered'">
        <el-tabs v-model="registered">
          <el-tab-pane label="注册账号" name="registered">
            <el-form
              ref="VerificationForm"
              :model="VerificationForm"
              label-width="72px"
              :rules="VerificationFormRules"
              key="VerificationForm"
            >
              <el-form-item prop="three">
                <label slot="label">
                  <span class="fl">地</span>
                  <span class="fr">区:</span>
                </label>
                <el-cascader
                  v-model="VerificationForm.three"
                  :props="props1"
                  @change="cascaderChange1"
                ></el-cascader>
              </el-form-item>

              <el-form-item prop="two">
                <label slot="label">
                  <span class="fl">学</span>
                  <span class="fr">校:</span>
                </label>
                <el-cascader
                  v-model="VerificationForm.two"
                  :props="props2"
                  ref="cascader"
                  :key="key2"
                  @change="cascaderChange2"
                  filterable
                  :disabled="!VerificationForm.three"
                  placeholder="请先选择地区"
                ></el-cascader>
              </el-form-item>
              <el-form-item prop="fullName">
                <label slot="label">
                  <span class="fl">姓</span>
                  <span class="fr">名:</span>
                </label>
                <el-input v-model="VerificationForm.fullName"></el-input>
              </el-form-item>
              <el-form-item prop="username">
                <label slot="label">
                  <span class="fl">账</span>
                  <span class="fr">号:</span>
                </label>
                <el-input v-model="VerificationForm.username"></el-input>
              </el-form-item>
              <el-form-item prop="password">
                <label slot="label">
                  <span class="fl">密</span>
                  <span class="fr">码:</span>
                </label>
                <el-input v-model="VerificationForm.password" show-password></el-input>
              </el-form-item>
              <el-form-item label="手机号:" prop="mobile">
                <el-input v-model="VerificationForm.mobile"></el-input>
              </el-form-item>
              <el-form-item label="验证码:" class="VerificationInput" prop="code">
                <el-input placeholder="请输入验证码" v-model="VerificationForm.code">
                  <span
                    slot="suffix"
                    class="getVerificationCode"
                    @click.prevent="getVerificationCode(1)"
                    >{{ getCodeTime }}</span
                  >
                </el-input>
              </el-form-item>
              <el-form-item class="checkbox" prop="checkbox">
                <el-checkbox v-model="VerificationForm.checkbox"></el-checkbox>
                <span @click.stop="centerDialogVisible = true" class="agreementText"
                  >我已同意用户注册协议</span
                >
                <p class="goLogin" @click="fromstatus = 'login'">已有账号去登录</p>
              </el-form-item>
              <el-form-item>
                <el-button @click.prevent="RegisteredAccount">创建账号</el-button>
              </el-form-item>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 找回密码 -->
      <div class="RePassword from" v-else-if="fromstatus == 'RePassword'">
        <el-tabs v-model="RePassword">
          <el-tab-pane label="找回密码" name="RePassword">
            <el-form
              ref="RePasswordForm"
              :model="RePasswordForm"
              label-width="10px"
              :rules="RePasswordFromRules"
            >
              <el-form-item prop="mobile">
                <el-input
                  placeholder="请输入手机号"
                  v-model="RePasswordForm.mobile"
                ></el-input>
              </el-form-item>
              <el-form-item class="VerificationInput" prop="code">
                <el-input placeholder="请输入验证码" v-model="RePasswordForm.code">
                  <span
                    class="getVerificationCode"
                    slot="suffix"
                    @click.prevent="getVerificationCode(3)"
                    >{{ getCodeTime }}</span
                  >
                </el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  style="width: 194px"
                  v-model="RePasswordForm.verifycode"
                ></el-input>
                <img style="width: 140px" :src="loginPCode" alt @click="getOnly" />
                <p class="ForgotPassword" @click="fromstatus = 'login'">想起密码</p>
              </el-form-item>
              <el-form-item>
                <el-button @click.prevent="fromStatusChange('')">下一步</el-button>
              </el-form-item>
              <p class="copyright">鄂ICP备18021828号-2</p>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
      <!-- 重置密码 -->
      <div class="resetPassword from" v-else>
        <el-tabs v-model="resetPassword">
          <el-tab-pane label="找回密码" name="resetPassword">
            <el-form
              ref="resetPasswordForm"
              :model="resetPasswordForm"
              :rules="resetPasswordFormRules"
              label-width="10px"
            >
              <el-form-item prop="newPas">
                <el-input
                  placeholder="新密码 6-16位字母或数字"
                  v-model="resetPasswordForm.newPas"
                ></el-input>
              </el-form-item>
              <el-form-item class="VerificationInput" prop="newPasAG">
                <el-input
                  placeholder="重复密码"
                  v-model="resetPasswordForm.newPasAG"
                ></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click.prevent="fromStatusChange('login')">提交</el-button>
              </el-form-item>
              <p class="copyright">鄂ICP备18021828号-2</p>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <el-dialog
      title="用户注册协议"
      :visible.sync="centerDialogVisible"
      width="30%"
      center
    >
      <div>
        <p>
          作为账号运营者，代表申请人向悦享成绩单申请使用服务，同时负责对申请人的账号进行管理，包括但不限于管理成绩单邮件、短信发送、学员信息管理等。
        </p>
        <p>申请人承诺:</p>
        <p>
          1、所有提交材料真实准确，合法有效，如材料不实或不完整，悦烹成绩单有权拒绝提供服务，或随时收回账号并停止服务，因此产生的一切责任由申请人自行承担。
        </p>
        <p>
          2、申请人对账号的运营和维护遵照法律法规、政策、葳旭成绩单服务协议及相关规则规范，不侵犯第三人的合法权益，如违反上述承诺，责任自行承担。
        </p>
        <p>3、申请人对该账号的一切行为及该账号运营下的成员行为和后果承担责任。</p>
        <p>
          4、申请人保证其发送成绩单的学生均为其实际带班的学生，保证不对悦烹成绩单任何服务或服务的使用或获得，进行复制、拷贝、出售或用于包括但不限于广告及任何其他商业目的。
        </p>
        <p>
          5、电请悦享成绩单免费服务，需保证填写的信息真实，并在必要的时候提供教师资格证等相关证件以验证身份真实性。如填写信息不真实，因此产生的一切责任由申请人自行承担。
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="agree">我同意</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import http from "../http/api";
import changeDataKey from "@/utils/changeDataKey";
import defaultRules from "@/utils/formRules.js";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    let that = this;
    return {
      fromstatus: "login",
      activeName: "first",
      registered: "registered",
      RePassword: "RePassword",
      resetPassword: "resetPassword",
      // 登录表单
      loginForm: {
        app: "SCHOOL_REPORT",
        username: "",
        password: "",
        sessionid: "",
        code: "",
      },

      loginFormRules: {
        username: [
          {
            validator: defaultRules().username,
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: defaultRules().password,
            trigger: "blur",
          },
        ],
        code: [
          {
            validator: defaultRules().Pcode,
            trigger: "blur",
          },
        ],
      },
      loginPCode: "",
      // 验证码登录表单
      codeLoginFrom: {
        app: "SCHOOL_REPORT",
        mobile: "",
        sessionid: "",
        code: "",
        verifycode: "",
      },
      codeLoginFromRules: {
        mobile: [
          {
            validator: defaultRules().mobile,
            trigger: "blur",
          },
        ],
        code: [
          {
            validator: defaultRules().code,
            trigger: "blur",
          },
        ],
        verifycode: [
          {
            validator: defaultRules().Pcode,
            trigger: "blur",
          },
        ],
      },
      getCodeTime: "获取验证码",
      timer: null,
      // 注册表单
      VerificationForm: {
        three: "",
        org: "",
        fullName: "",
        username: "",
        password: "",
        mobile: "",
        code: "",
        checkbox: false,
        province: "",
        city: "",
        area: "",
        app: "SCHOOL_REPORT",
        two: [],
      },
      VerificationFormRules: {
        three: [
          {
            type: "array",
            required: true,
            message: "请选择地区",
            trigger: "change",
          },
        ],
        two: [
          {
            type: "array",
            required: true,
            message: "请选择学校",
            trigger: "blur",
          },
        ],
        password: [{ validator: defaultRules().password, trigger: "blur" }],
        mobile: [{ validator: defaultRules().VerificationMobile, trigger: "blur" }],
        code: [{ validator: defaultRules().code, trigger: "blur" }],
        checkbox: [{ required: true, message: "请同意用户注册协议", trigger: "blur" }],
        fullName: [{ validator: defaultRules().fullName, trigger: "blur" }],
        username: [{ validator: defaultRules().username, trigger: "blur" }],
      },
      centerDialogVisible: false,
      // 找回密码表单
      RePasswordForm: {
        app: "SCHOOL_REPORT",
        mobile: "",
        code: "",
        sessionid: "",
        verifycode: "",
      },
      RePasswordFromRules: {
        mobile: [
          {
            validator: defaultRules().mobile,
            trigger: "blur",
          },
        ],
        code: [
          {
            validator: defaultRules().code,
            trigger: "blur",
          },
        ],
      },
      resetPasswordForm: {
        newPas: "",
        newPasAG: "",
      },
      resetPasswordFormRules: {
        newPas: [
          {
            validator: defaultRules().password,
            trigger: "blur",
          },
        ],
        newPasAG: [
          {
            validator: defaultRules().password,
            trigger: "blur",
          },
        ],
      },
      // 级联选择器选择地区
      props1: {
        lazy: true,
        async lazyLoad(node, resolve) {
          if (node.level == 0) {
            const { data: data } = await http.getProvinceOption();
            if (data.length && data.length > 0) {
              resolve(changeDataKey.changeKey(data));
            } else {
              resolve({});
              this.$message.error({
                message: "获取省份异常,请稍后重试",
                type: "error",
                duration: 3000,
              });
            }
          } else {
            const { data: data } = await http.getCityOption({
              parentCode: node.value,
            });
            if (data.length && data.length > 0) {
              if (node.level == 2) {
                data.forEach((item) => {
                  item.leaf = "leaf";
                });
                return resolve(changeDataKey.changeKey(data));
              }
              resolve(changeDataKey.changeKey(data));
            } else {
              resolve({});
              this.$message.error({
                message: "获取省份异常,请稍后重试",
                type: "error",
                duration: 3000,
              });
            }
          }
        },
      },
      key2: 0,
      // 级联选择器选择学校
      props2: {
        lazy: true,
        async lazyLoad(node, resolve) {
          if (node.level == 0) {
            resolve([
              {
                value: 0,
                label: "小学",
              },
              {
                value: 1,
                label: "初中",
              },
            ]);
          } else {
            // console.log(that.$refs.cascader.$el.children[0].children[0]);
            if (that.$refs.cascader.$el.children[0].children[0]) {
              that.$refs.cascader.$el.children[0].children[0].focus();
            }

            if (!node.value) {
              const { data: data } = await http.getPrimarySchool({
                code: that.VerificationForm.area,
              });
              if (data.length && data.length > 0) {
                data.forEach((item) => {
                  item.leaf = "leaf";
                });

                return resolve(changeDataKey.changeKey(data));
              } else {
                resolve({});
              }
            } else {
              const { data: data } = await http.getMiddleSchool({
                code: that.VerificationForm.area,
              });
              if (data.length && data.length > 0) {
                data.forEach((item) => {
                  item.leaf = "leaf";
                });

                return resolve(changeDataKey.changeKey(data));
              } else {
                resolve({});
              }
            }
          }
        },
      },
    };
  },
  created() {
    // 获取标识
    this.getOnly();
  },
  mounted() {},
  methods: {
    // 获取标识
    async getOnly() {
      const data = await http.getOnly();
      if (data.status == 200) {
        this.loginForm.sessionid = data.data;
        this.codeLoginFrom.sessionid = data.data;
        this.RePasswordForm.sessionid = data.data;
        this.getPCode(data.data);
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    // 获取图片验证码
    async getPCode(id) {
      const data = await http.getPCode({
        sessionid: id,
      });
      if (data.status == 200) {
        this.loginPCode = "data:image/jpeg;base64," + data.data;
      } else {
        this.$message.error({
          message: data.msg,
          type: "error",
          duration: 3000,
        });
      }
    },
    handleClick(tab, event) {
      this.$refs["loginForm"].resetFields();
      this.$refs["codeLoginFrom"].resetFields();
      this.getOnly();
    },
    // 表单切换
    fromStatusChange(status) {
      // 获取标识
      this.getOnly();
      if (!status) {
        this.$refs["RePasswordForm"].validate(async (valid) => {
          if (valid) {
            const data = await http.codeLogin(this.RePasswordForm);

            if (data.status == 200) {
              if (data.data.code == 200) {
                this.$message.success({
                  message: "验证成功",
                  type: "success",
                  duration: 3000,
                });
                window.localStorage.setItem("Authorization", data.data.data);
                if (this.timer) {
                  this.getCodeTime = "获取验证码";
                  clearInterval(this.timer);
                }
                return (this.fromstatus = status);
              } else {
                return this.$message.success({
                  message: data.data.msg,
                  type: "success",
                  duration: 3000,
                });
              }
            } else {
              return this.$message.success({
                message: "网络异常请重试",
                type: "success",
                duration: 3000,
              });
            }
          } else {
            this.$message.error({
              message: "请输入正确的信息",
              type: "error",
              duration: 3000,
            });
            return false;
          }
        });
      } else if (status == "login") {
        this.$refs["resetPasswordForm"].validate(async (valid) => {
          if (valid) {
            if (this.resetPasswordForm.newPas !== this.resetPasswordForm.newPasAG) {
              return this.$message.error({
                message: "两次输入的密码不一致",
                type: "error",
                duration: 3000,
              });
            }
            const data = await http.codeChangePas({
              newPassword: this.resetPasswordForm.newPas,
            });
            if (data.status == 200) {
              if (data.data.code == 200) {
                this.$message.error({
                  message: "修改成功",
                  type: "error",
                  duration: 3000,
                });
                if (this.timer) {
                  this.getCodeTime = "获取验证码";
                  clearInterval(this.timer);
                }
                this.fromstatus = status;
              }
            } else {
              this.$message.error({
                message: "网络异常,请稍后重试",
                type: "error",
                duration: 3000,
              });
            }
          } else {
            this.$message.error({
              message: "请输入正确的信息",
              type: "error",
              duration: 3000,
            });
            return false;
          }
        });
      } else {
        if (this.timer) {
          this.getCodeTime = "获取验证码";
          clearInterval(this.timer);
        }
        this.fromstatus = status;
      }
    },
    // 获取找回密码验证码
    async getRePasswordCode() {
      const data = await http.getMobileCode({
        mobile: this.RePasswordForm.mobile,
      });
    },
    //获取注册验证码
    async getVerificationCode(type) {
      if (this.timer) return null;

      let mobileReg = /^1[0-9]{10}$/;
      let mobile = 0;
      // 倒计时

      if (type == 1) {
        mobile = this.VerificationForm.mobile;
        if (!mobile.trim())
          return this.$message.error({
            message: "请输入手机号",
            type: "error",
            duration: 3 * 1000,
          });
        let { data: data } = await http.isRegistered({
          mobile: mobile,
        });
        if (data.code == 200)
          return this.$message.error({
            message: "该手机号已注册",
            type: "error",
            duration: 3 * 1000,
          });
      } else if (type == 2) {
        mobile = this.codeLoginFrom.mobile;
        if (!mobile.trim() || mobile.trim().length !== 11)
          return this.$message.error({
            message: "请输入正确的手机号",
            type: "error",
            duration: 3 * 1000,
          });
        let { data: data } = await http.isRegistered({
          mobile: mobile,
        });
        if (data.code !== 200) {
          return this.$message.error({
            message: "该手机号未注册",
            type: "error",
            duration: 3 * 1000,
          });
        }
      } else if (type == 3) {
        mobile = this.RePasswordForm.mobile;

        if (!mobile.trim() || mobile.trim().length !== 11)
          return this.$message.error({
            message: "请输入正确的手机号",
            type: "error",
            duration: 3 * 1000,
          });
        let { data: data } = await http.isRegistered({
          mobile: mobile,
        });
        if (data.code !== 200) {
          return this.$message.error({
            message: "该手机号未注册",
            type: "error",
            duration: 3 * 1000,
          });
        }
      }
      if (type == 0) return null;
      if (!mobileReg.test(mobile)) {
        return this.$message.error({
          message: "请输入正确的手机号码",
          type: "error",
          duration: 3 * 1000,
        });
      }

      let { data: isMobile } = await http.getMobileCode({
        mobile: mobile,
      });

      if (isMobile.code == 200) {
        let time = 59;
        this.timer = setInterval(() => {
          this.getCodeTime = ` ( ${time} ) 后重试`;
          time--;
          if (time < 0) {
            clearInterval(this.timer);
            this.getCodeTime = "获取验证码";
          }
        }, 1000);

        // 倒计时
        this.$message.error({
          message: "获取验证码成功",
          type: "error",
          duration: 3 * 1000,
        });
      }
    },
    // 注册
    RegisteredAccount() {
      this.$refs["VerificationForm"].validate(async (valid) => {
        if (valid) {
          if (!this.VerificationForm.checkbox)
            return this.$message.error({
              message: "请同意用户注册协议",
              type: "error",
              duration: 3 * 1000,
            });

          if (this.VerificationForm.two.length !== 2)
            return this.$message.error({
              message: "请选择学校",
              type: "error",
              duration: 3 * 1000,
            });

          const data = await http.RegisteredAccount(this.VerificationForm);

          if (data.status == 200) {
            if (data.data.code == 200) {
              this.$message.error({
                message: "注册成功",
                type: "error",
                duration: 3 * 1000,
              });
              this.fromstatus = "login";
            } else {
              this.$message.error({
                message: data.data.msg,
                type: "error",
                duration: 3 * 1000,
              });
            }
          } else {
            this.$message.error({
              message: data.statusText,
              type: "error",
              duration: 3 * 1000,
            });
          }
        } else {
          this.$message.error({
            message: "请输入正确注册信息",
            type: "error",
            duration: 3 * 1000,
          });
          return false;
        }
      });
    },
    // 同意协议
    agree() {
      this.VerificationForm.checkbox = true;
      this.centerDialogVisible = false;
    },
    // 登录
    login() {
      this.$refs["loginForm"].validate(async (valid) => {
        if (valid) {
          const { data: data } = await http.login(this.loginForm);

          if (data.code == 200) {
            this.$message.error({
              message: "登录成功",
              type: "error",
              duration: 3 * 1000,
            });
            if (this.timer) {
              clearInterval(this.timer);
              this.getCodeTime = "获取验证码";
            }
            window.localStorage.setItem("Authorization", data.data);
            this.$router.push("addreport");
          } else {
            this.$message.error({
              message: data.msg,
              type: "error",
              duration: 3 * 1000,
            });
          }
        } else {
          this.$message.error({
            message: "请输入正确的信息",
            type: "error",
            duration: 3 * 1000,
          });
          return false;
        }
      });
    },
    // 验证码登录
    codeLogin() {
      this.$refs["codeLoginFrom"].validate(async (valid) => {
        if (valid) {
          const { data: data } = await http.codeLogin(this.codeLoginFrom);
          if (data.code == 200) {
            this.$message.error({
              message: "登录成功",
              type: "error",
              duration: 3 * 1000,
            });

            window.localStorage.setItem("Authorization", data.data);
            if (this.timer) {
              this.getCodeTime = "获取验证码";
              clearInterval(this.timer);
            }
            this.$router.push("addreport");
          } else {
            this.$message.error({
              message: data.msg,
              type: "error",
              duration: 3 * 1000,
            });
          }
        } else {
          this.$message.error({
            message: "请输入正确的信息",
            type: "error",
            duration: 3 * 1000,
          });
          return false;
        }
      });
    },
    // 选择地区
    cascaderChange1(val) {
      if (val.length && val.length == 3) {
        this.VerificationForm.province = val[0];
        this.VerificationForm.city = val[1];
        this.VerificationForm.area = val[2];
      }
      this.VerificationForm.org = "";

      this.$refs.cascader.panel.store.nodes = [];
      this.key2 += 1;
    },
    // 选择学校
    cascaderChange2(val) {
      if (val.length && val.length == 2) {
        this.VerificationForm.org = val[1];
      }
    },
    // 获取学校
    async areaSelect() {},
  },
};
</script>
<style lang="scss" scoped>
.login {
  width: 100vw;
  height: 100vh;
  min-height: 820px;
  min-width: 1366px;
  background-color: #f9f9f9;
  .loginTop {
    width: 100%;
    height: 80px;
    background-color: #fff;
    h1 {
      width: 202px;
      height: 64px;
      margin: 8px 0 8px 19px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .topAbout {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #838f99;
      height: 100%;
      line-height: 80px;
      margin-right: 70px;
    }
  }
  .loginContent {
    position: relative;
    width: 100%;
    height: calc(100vh - 80px);
    min-height: 820px;
    .welcome {
      width: 265px;
      height: 58px;
      position: absolute;
      top: 71px;
      left: 19.23%;
      background: url("../assets/Welcome.png") center no-repeat;
      background-size: contain;
    }
    .welcomeCH {
      width: 160px;
      height: 56px;
      position: absolute;
      top: 145px;
      left: 19.23%;
      background: url("../assets/WelcomeCH.png") center no-repeat;
      background-size: contain;
    }
    .loginLogo {
      width: 318px;
      height: 128px;
      position: absolute;
      top: 186px;
      left: 19.23%;
      background: url("../assets/logo.png") center right no-repeat;
      background-size: 450px 128px;
    }
    .eavesLeft {
      width: 101px;
      height: 145px;
      position: absolute;
      top: 214px;
      left: 9%;
      background: url("../assets/eaves.png") bottom left no-repeat;
      background-size: 211px;
    }
    .eavesRight {
      width: 151.85px;
      height: 180px;
      position: absolute;
      top: 50px;
      left: 40.76%;
      background: url("../assets/eaves.png") right bottom no-repeat;
      background-size: 300px 268px;
    }
    .focus {
      width: 542px;
      height: 530.21px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    ::v-deep.from {
      width: 464px;
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      right: 12.78%;
      transform: translateY(-50%);
      border-radius: 16px;
      .el-tabs__item.is-active {
        color: #eb6123 !important;
      }
      .el-tabs__item:hover {
        color: #eb6123 !important;
        cursor: pointer;
      }
      .el-tabs__active-bar {
        background-color: #eb6123;
        height: 3px;
      }
      .el-tabs__header {
        margin-bottom: 0;
      }
      .el-tabs__nav {
        left: 50%;
        transform: translateX(-50%) !important;
        .el-tabs__item {
          font-size: 22px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #838f99;
          height: 96px;
          line-height: 96px;
          padding: 0 30px;
        }
        .el-tabs__active-bar {
          bottom: 14px;
        }
      }
      .el-tabs__nav-wrap::after {
        display: none;
      }
      .el-form {
        width: 352px;
        margin: 0 auto;
        border-radius: 8px;
        padding-bottom: 4px;
        .el-form-item {
          vertical-align: middle;
          line-height: 96px;
          margin-bottom: 20px;
          .el-button {
            display: block;
            width: 100%;
            background: #eb6123;
            box-shadow: 0px 8px 16px 0px rgba(59, 196, 146, 0.15);
            border-radius: 28px;
            font-size: 22px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 30px;
            text-shadow: 0px 8px 16px rgba(59, 196, 146, 0.15);
            box-sizing: border-box;
            &:hover {
              border: 1px solid #eb6123;
              box-shadow: 0px 0px 5px 3px rgba($color: #eb6123, $alpha: 0.3);
            }
          }
          .el-input {
            input {
              border: 1px solid #8f8e8e;
              border-radius: 8px;
              height: 56px;
              font-size: 18px;
            }
          }
          img {
            height: 56px;
            vertical-align: middle;
            margin-left: 8px;
          }
          .el-form-item__label:before {
            display: none;
          }
        }
        .copyright {
          margin-bottom: 20px;
        }
      }
    }
    ::v-deep.loginFrom {
      .VerificationText {
        text-align: left;
        padding-left: 24px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6161;
        height: 20px;
        line-height: 24px;
      }
      .ForgotPassword {
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff6161;
        padding-right: 8px;
        height: 0px;
        line-height: 70px;
        cursor: pointer;
      }
      .el-form-item {
        .el-button {
          display: block;
          width: 100%;
          background: #eb6123;
          box-shadow: 0px 8px 16px 0px rgba(59, 196, 146, 0.15);
          border-radius: 28px;
          font-size: 22px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 30px;
          text-shadow: 0px 8px 16px rgba(59, 196, 146, 0.15);
        }
        .el-input {
          input {
            border: 1px solid #eb6123 !important;
          }
        }
      }
      .pCode.el-form-item {
        margin-bottom: 60px;
      }
      .goRegistered {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2c2d3a;
        margin-bottom: 24px;
        .goRegisteredLfet {
          color: #2c2d3a;
        }
        .goRegisteredRight {
          color: #eb6123;
          cursor: pointer;
        }
      }
      .copyright {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #838f99;
      }
      .el-input--suffix {
        margin-right: 10px;
      }
      .VerificationForm {
        .VerificationInput {
          .getVerificationCode {
            margin: 8px;
            display: inline-block;
            width: 126px;
            height: 40px;
            background-color: #eb6123;
            color: #fff;
            line-height: 40px;
            text-align: center;
            border-radius: 5px;
            cursor: pointer;
          }
        }
      }
    }
    ::v-deep.RePassword {
      .el-tabs__active-bar {
        width: 90px !important;
      }
      .ForgotPassword {
        text-align: right;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff5858;
        cursor: pointer;
        padding-right: 8px;
      }
      .copyright {
        margin-top: 80px;
      }
      .VerificationInput {
        .getVerificationCode {
          margin: 8px;
          display: inline-block;
          width: 126px;
          height: 40px;
          background-color: #eb6123;
          color: #fff;
          line-height: 40px;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
    .resetPassword {
      .VerificationInput {
        margin-bottom: 38px;
      }
    }
    ::v-deep.registeredFrom {
      .el-tabs__active-bar {
        width: 90px !important;
      }
      .el-form {
        width: 380px;
        .el-input--suffix {
          padding: 0;
        }
      }
      .el-form-item {
        margin-bottom: 8px;
        .el-form-item__label {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #6d7278;
          line-height: 56px;
          text-align: justify;
        }
        .el-form-item__content {
          .el-select {
            width: 100%;
            .el-input {
              width: 100%;
            }
          }
          .el-cascader {
            width: 100%;
          }
        }
      }
      .VerificationInput {
        .el-input__suffix {
          top: 50%;
          transform: translate3d(-5%, -35%, 0);
          cursor: pointer;
        }
        .getVerificationCode {
          display: inline-block;
          width: 126px;
          height: 40px;
          background-color: #eb6123;
          color: #fff;
          line-height: 40px;
          text-align: center;
          border-radius: 5px;
          cursor: pointer;
        }
      }
      .checkbox {
        .el-form-item__content {
          overflow: hidden;
        }
        .el-checkbox {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #838f99;
          float: left;
        }
        .agreementText {
          cursor: pointer;
        }
        p {
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ff5858;
          float: right;
        }
        .goLogin {
          cursor: pointer;
        }
      }
    }
    div {
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  ::v-deep.el-dialog {
    .el-dialog__header {
      font-weight: 700;
    }
    .el-dialog__body {
      p {
        margin-bottom: 5px;
        &:nth-of-type(1) {
          text-indent: 2em;
        }
      }
    }
  }
}
</style>
