export default {
  changeKey(data) {
    if (Array.isArray(data)) {
      let newData = []
      data.forEach((item, index) => {
        newData.push({})
        if (item.id) {
          newData[index].value = item.id
        }
        if (item.text) {
          newData[index].label = item.text
        }
        if (item.leaf) {
          newData[index].leaf = item.leaf
        }
      })
      return newData
    }

  }
}